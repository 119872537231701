<template>

  <div class="mt-1">

    <Menu></Menu>

    <div class="parent">

      <iframe v-show="isLoaded" class="iframe-container" v-bind:src="gameURL"></iframe>

    </div>

  </div>

</template>
<script>

import Menu from "@/components/casino/Menu";

export default {
  name: 'rocketman',
  components: {
    Menu
  },
  data: function () {
    return {
      loading:'',
      gameName: 'Rocket Man',
      isLoaded: false,
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","casinogames");
    var p = this.getProfile();
    if(!p) {

      this.setError("Login","Please login to proceed");
      this.$router.push({ name: 'login', params: { } });
      //return;
    }
  },
  computed: {

    app: function () {

      return this.$store.state.app;
    },

    profile: function() {

      return this.getProfile();

    },
    token: function(){

      return this.getAuth();
    },
    gameURL: function(){

      var tokn = this.getFasoAuth();

      var displayType = this.app ? "mobile" : this.isMobile() ? "mobile" : "desktop"
      displayType = "desktop"

      if(tokn === undefined || tokn === null || !tokn || tokn.length === 0 || tokn.length < 5 ) {

        tokn = "DEMO";
      }

      var src = "https://cdn.rocketman.elbet.com/?token="+tokn+"&version="+displayType+"&companyId=52&language=en&currency=KES"

      console.log('GAME URL '+src)

      return src

    }

  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {

        //JetX
        // react to route changes...
        this.gameName = to.params.gameName === undefined ? 'Rocket man' : to.params.gameName;
        this.categoryDesktop = to.params.categoryDesktop === undefined ? 'JetX' : to.params.categoryDesktop;
        this.categoryMobile = to.params.categoryMobile === undefined ? 'JetXMobile' : to.params.categoryMobile;
        this.isLoaded = true;
      }
    },
  },
  methods: {

    goToCasino: function(){

      this.$router.push({ name: 'casino', params: { } });

    },
    showDeposit: function() {

      document.getElementById("deposit-init").click();

    },
  },
  filters: {

    amount: function (val){

      if(val === undefined ) {

        return val

      }

      return parseFloat(val).toFixed(2).toLocaleString()

    }

  }
}
</script>

<style scoped>
.title_name{
  color: #fff;
}
.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.parent .banner {
  background: #1b5733;
  width: 100%;
  height: 48px;

}
.parent iframe {
  background: #000;
  border: none;
  flex-grow: 1;
}
h4{
  position: relative;
  top: .6em;
  right: 1em;
}
</style>